<template>
  <div class="labour-con">
    <title-con title="企业评分" />
    <div class="qiyphz-con">
      <div class="item-row">
        <img :src="require('@/assets/images/luma.png')" alt="" />
        <h1>{{ lmcode }}</h1>
        <h1>绿码企业</h1>
      </div>
      <div class="item-row">
        <img :src="require('@/assets/images/hangm.png')" alt="" />
        <h1>{{ hmcode }}</h1>
        <h1>黄码企业</h1>
      </div>
      <div class="item-row">
        <img :src="require('@/assets/images/hongm.png')" alt="" />
        <h1>{{ redCode }}</h1>
        <h1>红码企业</h1>
      </div>
    </div>
    <div class="subject-list-con">
      <img :src="require('@/assets/images/lmqy.png')" alt="" />
      <div class="list-c">
        <div v-if="greenCodeList.length != 0" class="arrange">
          <!-- <swiper
            :options="recSwiperOptionlml"
            :not-next-tick="notNextTickOfRecSwiperdylml"
            ref="recSwiperhz"
            class="swiper-item"
          >
            <swiper-slide v-for="(item, idx) in greenCodeList" :key="idx">
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </swiper-slide>
          </swiper> -->
          <div class="swiper-item">
            <div
              v-for="(item, idx) in greenCodeList"
              :key="idx"
              class="info-row"
            >
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 100px" v-if="greenCodeList.length == 0">
          <a-empty
            :image="require('@/assets/images/wusj.png')"
            description="暂无绿码企业"
          />
        </div>
      </div>
    </div>
    <div class="subject-list-con">
      <img :src="require('@/assets/images/humqy.png')" alt="" />
      <div class="list-c">
        <div v-if="yellowCodeList.length != 0" class="arrange">
          <!-- <swiper
            :options="recSwiperOptionHuml"
            :not-next-tick="notNextTickOfRecSwiperdyHuml"
            ref="recSwiperhz"
            class="swiper-item"
          >
            <swiper-slide v-for="(item, idx) in yellowCodeList" :key="idx">
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </swiper-slide>
          </swiper> -->
          <div class="swiper-item">
            <div
              v-for="(item, idx) in yellowCodeList"
              :key="idx"
              class="info-row"
            >
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 100px" v-if="yellowCodeList.length == 0">
          <a-empty
            :image="require('@/assets/images/wusj.png')"
            description="暂无黄码企业"
          />
        </div>
      </div>
    </div>
    <div class="subject-list-con">
      <img :src="require('@/assets/images/hmqy.png')" alt="" />
      <div class="list-c">
        <div v-if="redCodeFirst.length != 0" class="arrange">
          <!-- <swiper
            :options="recSwiperOptionhml"
            :not-next-tick="notNextTickOfRecSwiperdyhml"
            ref="recSwiperhz"
            class="swiper-item"
          >
            <swiper-slide v-for="(item, idx) in redCodeFirst" :key="idx">
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </swiper-slide>
          </swiper> -->
          <div class="swiper-item">
            <div
              v-for="(item, idx) in redCodeFirst"
              :key="idx"
              class="info-row"
            >
              <div class="subjectName">
                <span>{{ item.subjectName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 100px" v-if="redCodeFirst.length == 0">
          <a-empty
            :image="require('@/assets/images/wusj.png')"
            description="暂无红码企业"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
